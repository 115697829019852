import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { roundnessCalculator } from '@/components/DesignSystem/AtomicComponents/util';
import {
  fetchCatalogItemById,
  saveCart,
  setChooseAndRepeatModalVisibility,
} from 'src/redux/actions';
import Modal from '../Common/Modal';
import { mutateVariantItemWithCartData } from '@/utils/mutateVariantItemWithCartData';

const VariantChooseRepeatModal = (props) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    repeatIndex: null,
    theme: null,
  });

  const {
    variant: { chooseRepeatModal },
    productLoaderId,
  } = useSelector((state) => state.commonReducer);
  const cartData = useSelector((state) => state.cartReducer);
  const { theme, store_id } = useSelector((state) => state.storeReducer.store);

  useEffect(() => {
    if (chooseRepeatModal) {
      const productIndex = cartData.items.findIndex((el) => el.id === productLoaderId);
      setState((state) => ({ ...state, repeatIndex: productIndex }));
    }
  }, [chooseRepeatModal, cartData, productLoaderId]);

  useEffect(() => {
    if (chooseRepeatModal) {
      setState((state) => ({ ...state, theme: theme }));
    }
  }, [chooseRepeatModal, theme]);

  const onClose = () => {
    dispatch(setChooseAndRepeatModalVisibility(false));
  };

  const onIWillChooseBtnHandle = () => {
    /** get the details item for this item id */
    dispatch(
      fetchCatalogItemById({ store_id: store_id, item_id: productLoaderId }, true)
    );
    onClose();
  };

  const onRepeatBtnHandle = () => {
    const newCart = mutateVariantItemWithCartData(
      'repeat',
      cartData?.items?.[state.repeatIndex],
      cartData?.items?.[state.repeatIndex]?.variants_selected[
        cartData?.items?.[state.repeatIndex]?.variants_selected?.length - 1
      ],
      cartData
    );
    dispatch(saveCart(newCart));
    onClose();
  };

  return (
    chooseRepeatModal && (
      <Modal
        visible={chooseRepeatModal}
        customStyles={{
          paddingLeft: 0,
          paddingRight: 0,
        }}
        height="auto"
        animation={'slideUp'}
        className="bottom-modal variantChooseRepeatModal"
        onClose={onClose}
      >
        <span className="closeicon" onClick={onClose}>
          <img loading="lazy" src="/assets/images/cancel.png" alt="cancel" />
        </span>
        <div className="title">Choose a Variant</div>
        <div className="sub-title">Your Last Choice</div>
        <article className="variant-name">
          {
            cartData?.items?.[state.repeatIndex]?.variants_selected[
              cartData?.items?.[state.repeatIndex]?.variants_selected?.length - 1
            ]?.variant_name
          }
        </article>
        {
          <section className="footer">
            <button
              className="choose_btn pointer"
              style={{
                borderRadius: `${
                  state.theme ? `${roundnessCalculator(state.theme?.roundness)}` : '8px'
                }`,
                borderColor: `${
                  state.theme ? state.theme?.colors?.primary_color : '#EA5151'
                }`,
                color: `${state.theme ? state.theme?.colors?.primary_color : '#EA5151'}`,
              }}
              onClick={onIWillChooseBtnHandle}
            >
              I’LL CHOOSE
            </button>
            <button
              className="repeat_btn pointer"
              style={{
                backgroundColor: `${
                  state.theme ? state.theme?.colors?.primary_color : '#EA5151'
                }`,
                borderRadius: `${
                  state.theme ? `${roundnessCalculator(state.theme?.roundness)}` : '8px'
                }`,
                color: '#ffffff',
              }}
              onClick={onRepeatBtnHandle}
            >
              REPEAT
            </button>
          </section>
        }
      </Modal>
    )
  );
};

export default VariantChooseRepeatModal;
